import './App.css';

import logoBoosters from './img/logo/boosters.svg';
import logoBig from './img/logo/big.svg';
import logoNeighborhood from './img/logo/neighborhood.svg';

import appStoreBadge from './img/appStore.svg';
import googlePlayBadge from './img/googlePlay.png';

function App() {
  return (
    <div>
      <div className="video-wrapper">
        <div className="video-aspect">
          <video class="lower-video" src="/bbn-lower.mp4" autoPlay muted playsInline loop />
          <video class="booster-video" src="/booster.mp4" autoPlay muted playsInline loop />

          <h1 className="title" aria-label="Booster's Big Neighborhood">
            <img alt="" className="word boosters" src={logoBoosters} />
            <img alt="" className="word big" src={logoBig} />
            <img alt="" className="word neighborhood" src={logoNeighborhood} />
          </h1>

          <div className="content">
            <h2>Multiplayer spaceflight minigame mayhem! Available now on iOS and&nbsp;Android.</h2>

            <div className="store-badges">
              <a href="https://apps.apple.com/us/app/boosters-big-neighborhood/id1551743104">
                <img src={appStoreBadge} alt="Download on the App Store" className="store-badge" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.paulstraw.bbn">
                <img src={googlePlayBadge} alt="Get It on Google Play" className="store-badge" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <ul>
          <li><a href="https://discord.gg/deD6qVjN2Q">Discord</a></li>
          <li><a href="mailto:paulstraw@paulstraw.com">Support</a></li>
          <li><a href="https://paulstraw.com/bbn-privacy-policy">Privacy Policy</a></li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
